<template>
  <BittsModalTwo
    title="Set your sharing settings"
    :use-mask-to-close="true"
    @closed="handleClose"
    @action="handleSave"
  >
    <template #header>
      <div>
        <h3 class="title"> Set your sharing settings </h3>
        <p class="description">
          You can define Accounts and Fields to be shared with any new partner
          on Crossbeam.<br />
          Users will always be able to refine these settings at the partner
          level.
        </p>
      </div>
    </template>
    <SharingSettingsTab
      ref="sharingSettingsTabRef"
      :is-bulk="true"
      class="m-0"
    />
  </BittsModalTwo>
</template>

<script setup lang="ts">
import { BittsModalTwo } from '@crossbeam/bitts';

import { ref } from 'vue';
import { useRouter } from 'vue-router';

import SharingSettingsTab from '@/components/partners/DataSharingSettings/SettingsModal/SharingSettingsTab/components/SharingSettingsTab.vue';

const router = useRouter();

const sharingSettingsTabRef = ref<InstanceType<
  typeof SharingSettingsTab
> | null>(null);

function handleClose() {
  router.back();
}

async function handleSave() {
  const success = await sharingSettingsTabRef.value?.triggerBulkUpdate();
  if (success) router.push({ path: '/deal-navigator' });
}
</script>

<style lang="pcss" scoped>
.title {
  @apply text-neutral-text-strong font-bold text-xl text-center w-full mb-8;
}
.description {
  @apply text-neutral-text text-m text-center ml-24 mb-24;
}
</style>
