<template>
  <div>
    <BittsTooltip v-if="partnersUsingDefaults.length > 0">
      <template #title>
        <div class="font-bold">
          {{
            `Default sharing currently applies to ${partnersUsingDefaults.length} ${pluralize(partnersUsingDefaults.length, 'partner')}:`
          }}
        </div>
        <div>
          {{ partnersUsingDefaults.map((partner) => partner.name).join(', ') }}
        </div>
      </template>
      <BittsButton
        variant="ghost"
        size="small"
        @click="() => handlePartnerModal(true)"
        class="c-sharing-settings-item__partner-btn"
      >
        <template #extra-content>
          <BittsAvatarStack :org-list="partnersUsingDefaults" />
        </template>
      </BittsButton>
    </BittsTooltip>
    <SharingItemPartnersModal
      v-if="isOpenPartnerModal"
      :partners-using-defaults="partnersUsingDefaults"
      :close="() => handlePartnerModal(false)"
    />
  </div>
</template>

<script setup lang="ts">
import { BittsAvatarStack, BittsButton, BittsTooltip } from '@crossbeam/bitts';

import { pluralize } from 'humanize-plus';
import { computed, ref } from 'vue';

import usePopulations from '@/composables/usePopulations';
import { Partner } from '@/types/partners';

import SharingItemPartnersModal from './SharingItemPartnersModal.vue';

const props = defineProps<{
  populationId: number;
}>();

const { allPopulations, customPopsWithStatsAndSharing } = usePopulations();
const isOpenPartnerModal = ref(false);

const population = computed(() => {
  const populations = [
    ...allPopulations.value,
    ...customPopsWithStatsAndSharing.value,
  ];
  return populations.find((population) => population.id === props.populationId);
});

const partnersUsingDefaults = computed(
  () => population.value?.partnersUsingDefaults as Partner[],
);

function handlePartnerModal(isOpen: boolean) {
  isOpenPartnerModal.value = isOpen;
}
</script>

<style lang="pcss" scoped>
.c-sharing-settings-item__partner-btn {
  &.c-bitts-btn-small {
    @apply p-4;
  }
}
</style>
