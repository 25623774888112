// eslint-disable-next-line import/namespace, import/default, import/no-named-as-default, import/no-named-as-default-member
import buildTailwindTheme from '@/../../../tailwind-theme-builder';
import { pluralize } from 'humanize-plus';
import { compact, max, sortBy, uniqBy } from 'lodash';

import { usePartnersStore } from '@/stores/PartnersStore';

export const PLAY_INSIGHT_CUSTOMER = 'customer';
export const PLAY_INSIGHT_OPEN_OPPORTUNITY = 'open_opportunity';
export const PLAY_INSIGHT_WON_OPPORTUNITY = 'won_opportunity';

export const RECENTLY_WON_ID = 'recently-won';
export const PITCH_THE_STACK_ID = 'pitch-the-stack';
export const CO_SELL_TO_SUCCESS_ID = 'co-sell-to-success';
export const GET_WARM_INTRO_ID = 'get-warm-intro';
export const GET_INTEL_ID = 'get-intel';

const formatAmount = (amount, currencyCode) => {
  if (amount) {
    if (!currencyCode) {
      currencyCode = 'USD';
    }

    const formatter = new Intl.NumberFormat('en-US', {
      currency: currencyCode,
      style: 'currency',
    });

    return formatter.format(amount);
  }
};

const PARTNER_CUSTOMER_INSIGHT = 'partner-customer';
const PARTNER_OPEN_OPPORTUNITY_INSIGHT = 'partner-open-opportunity';

const uniquePartnerInsights = (insightType, insights) => {
  /*
    It is possible to have multiple insights of a single type for the same
    partner but sometimes we may want to count the insight only once per partner
  */
  const insightsMatchingType = insights.filter(
    ({ type }) => type === insightType,
  );
  return uniqBy(insightsMatchingType, (i) => i.data.partner_id);
};

const fromPartnerPopulationInsights = (insightType, rawPlay) => {
  const insights = uniquePartnerInsights(insightType, rawPlay.insights);

  if (insights.length === 0) {
    return null;
  }

  const partnersStore = usePartnersStore();

  function descriptionSuffix(type) {
    const insightsByType = insights.filter((insight) => insight.type === type);
    return (
      insightsByType.reduce((description, insight) => {
        const partnerName = partnersStore.getPartnerOrgByUuid(
          insight.data.partner_id,
        )?.name;
        if (!partnerName) return description;
        if (description) description += `, ${partnerName}`;
        else description += `${partnerName}`;
        return description;
      }, '') || 'Partner'
    );
  }

  switch (insightType) {
    case PARTNER_CUSTOMER_INSIGHT:
      return {
        type: PLAY_INSIGHT_CUSTOMER,
        description: `Customer of ${descriptionSuffix('partner-customer')}`,
      };
    case PARTNER_OPEN_OPPORTUNITY_INSIGHT:
      return {
        type: PLAY_INSIGHT_OPEN_OPPORTUNITY,
        description: `Open opportunity for ${descriptionSuffix('partner-open-opportunity')}`,
      };
  }
};

const roundToNextTensPlace = (n) => {
  /*
    Rounds to the next highest tens place
    1 -  09 -> 10
    10      -> 10
    11 - 19 -> 20
  */
  return n % 10 === 0 ? n : Math.ceil((n + 1) / 10) * 10;
};

const fromRecentlyWonInsights = (rawPlay) => {
  const insights = rawPlay.insights.filter(
    ({ type }) => type === 'recently-won-opportunity',
  );
  if (insights.length !== 0) {
    // Get most recent recently-won insight per partner
    const mostRecentWins = sortBy(insights, (i) => i.data.days_since_won);
    const uniqueToPartner = uniqBy(mostRecentWins, (i) => i.data.partner_id);
    const greatestTimeFrame = max(
      uniqueToPartner.map((i) => i.data.days_since_won),
    );

    const count = uniqueToPartner.length;

    return {
      type: PLAY_INSIGHT_WON_OPPORTUNITY,
      description: `${
        count === 1
          ? rawPlay.overlaps[0].partner_name
          : `${count} ${pluralize(count, 'partner')}`
      } won this deal in the last ${roundToNextTensPlace(greatestTimeFrame)}d`,
    };
  }
};

const reachOutToDescription = (rawPlay) => {
  return [
    { text: 'Reach out to ', highlight: false },
    {
      text: rawPlay.overlaps[0].partner_owner
        ? rawPlay.overlaps[0].partner_owner.owner_name
        : 'account owners',
      highlight: !!rawPlay.overlaps[0].partner_owner,
    },
    { text: ' from ', highlight: false },
    { text: rawPlay.overlaps[0].partner_name, highlight: true },
  ];
};

const bestPartnerPrefix = (overlap) =>
  `${overlap.partner_name} is the best partner to work with on this account because`;

const recentlyWonBestPartnerHighlight = (rawPlay) => {
  const overlap = rawPlay.overlaps[0];
  const insight = rawPlay.insights.filter(
    ({ type }) => type === 'recently-won-opportunity',
  )[0];

  const amount = insight?.data?.partner_opportunity_amount;
  const currencyCode = insight?.data?.partner_opportunity_currency_code;

  let amountSuffix = '';

  const formattedAmount = formatAmount(amount, currencyCode);

  if (formattedAmount) {
    amountSuffix = `for ${formattedAmount}`;
  }

  return `${bestPartnerPrefix(overlap)} they recently won a deal ${amountSuffix}`;
};

const tailwindTheme = buildTailwindTheme();

const RECENTLY_WON = {
  id: RECENTLY_WON_ID,
  definition: (rawPlay) => ({
    name: 'Recently Won',
    header: {
      background: tailwindTheme.colors.warning.accent,
      color: tailwindTheme.colors.warning.text,
      fade: [
        tailwindTheme.colors.warning.border,
        tailwindTheme.colors.warning.text,
      ],
    },
    insights: compact([
      fromRecentlyWonInsights(rawPlay),
      fromPartnerPopulationInsights(PARTNER_CUSTOMER_INSIGHT, rawPlay),
    ]),
    description: reachOutToDescription(rawPlay),
    outcome: [
      { text: 'Find the Champion and Economic buyer', highlight: false },
    ],
    explanation: {
      about: [
        "This play leverages your partner's recent success to gain access to key decision-makers within the target company, enhancing your chances of winning deals and streamlining the sales process.",
      ],
      for: [
        'Prospects who are already customers or opportunities of your partners',
      ],
      conditions: [
        '1. Partner must have recently closed a deal with the target company',
        '2. Partner should have a strong relationship with the contact',
        '3. Partner must be willing to facilitate introductions and share critical information',
      ],
      risks: [
        "1. Dependency on the partner's willingness and ability to facilitate connections and share information",
        '2. The champion or economic buyer may not be receptive to further engagement',
      ],
    },
    message: `Hi,
Can we connect about ${rawPlay.overlaps[0].partner_record_name}?

Crossbeam, a tool our partnership teams collaborate on, shows that you own the account.
I'm looking to learn more about their org chart and deal process.

I'm happy to share information with you on deals I'm working on or have recently closed. Let me know a convenient time for a call.`,
    bestPartnerHighlight: recentlyWonBestPartnerHighlight(rawPlay),
  }),
};

const PITCH_THE_STACK = {
  id: PITCH_THE_STACK_ID,
  definition: (rawPlay) => ({
    name: 'Pitch the Stack',
    header: {
      background: tailwindTheme.colors.accent.accent,
      color: tailwindTheme.colors.accent[700],
      fade: [
        tailwindTheme.colors.accent[300],
        tailwindTheme.colors.accent[700],
      ],
    },
    insights: compact([
      fromPartnerPopulationInsights(
        PARTNER_CUSTOMER_INSIGHT,
        rawPlay,
        'integration partner',
      ),
    ]),
    description: [
      {
        text: 'Show your contact how your product combines with partners to create a more powerful solution',
        highlight: false,
      },
    ],
    outcome: [
      {
        text: 'Increase win rate with a stronger value story',
        highlight: false,
      },
    ],
    explanation: {
      about: [
        'When an account already uses multiple partner products, seize the chance to showcase the power of combining these solutions into a high-impact "stack." Highlight the synergy of using them together rather than focusing on individual integrations.',
      ],
      for: [
        'Prospects, Opportunities, and Customers where you are trying to get their attention, differentiate from competitors, or provide an informed pitch that makes you stand out.',
      ],
      conditions: [
        'You must have multiple partners who already have this account as a customer',
      ],
      risks: [
        '1. The products don’t work well together or',
        '2. The partner presence is a coincidence',
      ],
    },
    message: null,
    bestPartnerHighlight: `${bestPartnerPrefix(rawPlay.overlaps[0])} they can help you pitch the stack`,
  }),
};

const CO_SELL_TO_SUCCESS = {
  id: CO_SELL_TO_SUCCESS_ID,
  definition: (rawPlay) => ({
    name: 'Co-Sell to Success',
    header: {
      background: tailwindTheme.colors.success.border,
      color: tailwindTheme.colors.green[700],
      fade: [
        tailwindTheme.colors.success.border,
        tailwindTheme.colors.green[700],
      ],
    },
    insights: compact([
      fromPartnerPopulationInsights(PARTNER_OPEN_OPPORTUNITY_INSIGHT, rawPlay),
    ]),
    description: [
      {
        text: 'Coordinate co-selling with account owners from ',
        highlight: false,
      },
      { text: rawPlay.overlaps[0].partner_name, highlight: true },
    ],
    outcome: [
      {
        text: 'Increase win rates for you and partners as customers see the benefits of a unified solution',
        highlight: false,
      },
    ],
    explanation: {
      about: [
        'When one of your partners is actively selling to an opportunity at the same time as you (the account is an “opportunity” of both you and your partner), you can directly coordinate and communicate with your counterpart at the partner company to help align on goals, contacts, messaging, and timelines ',
      ],
      for: [
        'Opportunities where there is an open opportunity at a partner company as well',
      ],
      conditions: ['Must be an opportunity for both companies'],
      risks: [
        '1. The partnership is not important to the account or',
        '2. The partner is not influential to them',
      ],
    },
    message: `Hi,
Can we connect about ${rawPlay.overlaps[0].partner_record_name}?

Crossbeam, a tool our partnership teams collaborate on, shows that you own the account.
I’m looking to identify mutually beneficial ways that our partnership can help them with their goals.

Let me know a convenient time for a call. Then we can also chat about other accounts I can help with or collaborate on.
`,
    bestPartnerHighlight: `${bestPartnerPrefix(rawPlay.overlaps[0])} they can help you co-sell to success`,
  }),
};

const GET_WARM_INTRO = {
  id: GET_WARM_INTRO_ID,
  definition: (rawPlay) => ({
    name: 'Get Warm Intro',
    header: {
      background: tailwindTheme.colors.beta.border,
      color: tailwindTheme.colors.pink[700],
      fade: [tailwindTheme.colors.beta.border, tailwindTheme.colors.pink[700]],
    },
    insights: compact([
      fromPartnerPopulationInsights(PARTNER_CUSTOMER_INSIGHT, rawPlay),
      fromPartnerPopulationInsights(PARTNER_OPEN_OPPORTUNITY_INSIGHT, rawPlay),
    ]),
    description: reachOutToDescription(rawPlay),
    outcome: [
      {
        text: 'Identify the right contacts and get a referral or introduction',
        highlight: false,
      },
    ],
    explanation: {
      about: [
        'Ask a partner to reach out to your active opportunity or prospect and endorse your product, highlighting the existence of your partnership and how well your companies collaborate with each other. ',
      ],
      for: [
        'Prospects who are already customers or opportunities of your partners that can introduce you to their contacts',
      ],
      conditions: ['Partner must be willing to facilitate introductions'],
      risks: [
        'The willingness of partner account owners to provide warm introductions may vary based on their existing relationships and commitments. Ensure that the contacts you seek introductions to align with your target audience and business goals to make the most of this play',
      ],
    },
    message: `Hi,
Can we connect about ${rawPlay.overlaps[0].partner_record_name}?

Crossbeam, a tool our partnership teams collaborate on, shows that you own the account.
They’re a prospect for me. I want to learn more about their org chart and who the right folks to talk to are.

I'm happy to share information with you on deals I'm working on or have recently closed. Let me know a convenient time for a call.`,
    bestPartnerHighlight: `${bestPartnerPrefix(rawPlay.overlaps[0])} they can help you get a warm intro`,
  }),
};

const GET_INTEL = {
  id: GET_INTEL_ID,
  definition: (rawPlay) => ({
    name: 'Get Intel',
    header: {
      background: tailwindTheme.colors.neutral['border-focus'],
      color: tailwindTheme.colors.blue[700],
      fade: [
        tailwindTheme.colors.neutral['border-focus'],
        tailwindTheme.colors['brand-navy'],
      ],
    },
    insights: compact([
      fromPartnerPopulationInsights(PARTNER_CUSTOMER_INSIGHT, rawPlay),
    ]),
    description: reachOutToDescription(rawPlay),
    outcome: [
      {
        text: 'Validate your contacts and get the scoop on the sales process',
        highlight: false,
      },
    ],
    explanation: {
      about: [
        'This is a play for partner customers. Ask your partner to validate the contacts you’re working with and for any insights into the deal process.',
      ],
      for: [
        'Early deals that need more org chart knowledge. As well as getting inside knowledge on each coming deal stage.',
      ],
      conditions: [
        '1. Your partner needs to have a relationship and credibility with someone important the account, and',
        '2. Partner must be willing to share critical information',
      ],
      risks: ["Dependency on the partner's willingness to share information"],
    },
    message: `Hi,
Can we connect about ${rawPlay.overlaps[0].partner_record_name}?

Crossbeam, a tool our partnership teams collaborate on, shows that you own the account.
I’m working on a deal with them and want to learn more about their org chart and deal process.

I'm happy to share information with you on deals I'm working on or have recently closed. Let me know a convenient time for a call.`,
    bestPartnerHighlight: `${bestPartnerPrefix(rawPlay.overlaps[0])} they can help you get a get intel`,
  }),
};

export default {
  [RECENTLY_WON.id]: RECENTLY_WON,
  [PITCH_THE_STACK.id]: PITCH_THE_STACK,
  [CO_SELL_TO_SUCCESS.id]: CO_SELL_TO_SUCCESS,
  [GET_WARM_INTRO.id]: GET_WARM_INTRO,
  [GET_INTEL.id]: GET_INTEL,
};
