<template>
  <div class="c-field-presets-tab">
    <BittsAlert
      color="info"
      message="Create presets of fields that you and your team will be using for data sharing."
    />
    <BittsButton
      text="Create preset"
      type="neutral"
      variant="outline"
      class="create-btn"
      :left-icon="['far', 'plus']"
      @click="openModal()"
      :disabled="!ready"
    />
    <BittsLoading :is-loading="!ready">
      <ListContainer
        :list="sortedPresets"
        :on-click-item="(preset) => openModal(preset.id)"
        :get-key="(preset) => preset.id"
      >
        <template #default="{ item: preset, index }">
          <div
            class="preset"
            :class="{
              'preset--first': index === 0,
              'preset--last': index + 1 === sortedPresets.length,
            }"
          >
            <div class="flex items-center gap-12">
              <div class="font-bold">{{ preset.name }}</div>
              <BittsTag
                v-if="preset.preset_type === 'crossbeam_default'"
                text="Default"
                color="neutral"
              />
            </div>
            <div class="flex items-center gap-12">
              <BittsTag
                :text="`${preset.source_fields.length} ${pluralize(preset.source_fields.length, 'field')}`"
                color="secondary"
              />
              <FontAwesomeIcon :icon="['fak', 'chevron-right']" />
            </div>
          </div>
        </template>
      </ListContainer>
    </BittsLoading>
  </div>
</template>

<script setup lang="ts">
import {
  BittsAlert,
  BittsButton,
  BittsLoading,
  BittsTag,
} from '@crossbeam/bitts';

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { pluralize } from 'humanize-plus';
import { storeToRefs } from 'pinia';

import ListContainer from '@/components/ListContainer.vue';

import { useFieldPresets } from '@/composables/useFieldPresets';
import { useDataSharePresetsStore } from '@/stores';

type Props = {
  feedId: number;
  sourceId?: number;
};
const { feedId, sourceId } = defineProps<Props>();

const { sortedPresets, openModal } = useFieldPresets({ feedId, sourceId });
const { ready } = storeToRefs(useDataSharePresetsStore());
</script>

<style scoped lang="pcss">
.c-field-presets-tab {
  @apply flex flex-col gap-20;
  .c-loading {
    @apply max-h-[50px];
  }
}
.create-btn {
  @apply size-fit;
}
.preset {
  @apply flex justify-between px-20 py-16 w-full;
  &:hover {
    @apply bg-neutral-background-weak cursor-pointer;
  }
  &--first {
    @apply rounded-t-16;
  }
  &--last {
    @apply rounded-b-16;
  }
}
</style>
