<template>
  <div class="population-filters">
    <div
      v-for="(filterGroup, groupIndex) in filterGroupList"
      :key="`filter-group_${filterGroup.tableName}`"
      class="population-filters__group"
      @mouseover="hoveredIndex = groupIndex"
      @mouseleave="hoveredIndex = -1"
    >
      <div class="bg-neutral-100 p-8 rounded-bts-base">
        <span class="flex justify-between items-center">
          <span class="population-filters__group-name">
            {{ formatFilterTitle(filterGroup) }}
          </span>
          <span
            v-if="!hasNewOnboarding"
            :class="{
              'cursor-not-allowed': !canEditPopulation,
            }"
            class="population-filters__delete-filter-group-text"
            @click="handleDeleteFilterGroup(filterGroup)"
          >
            Delete Filter Group
          </span>
          <BittsButton
            v-else
            :center-icon="['fak', 'delete']"
            size="x-small"
            variant="ghost"
            type="danger"
            class="population-filters__delete-filter-group-button"
            :class="{
              'cursor-not-allowed': !canEditPopulation,
              visible: hoveredIndex === groupIndex,
              invisible: hoveredIndex !== groupIndex,
            }"
            @click="handleDeleteFilterGroup(filterGroup)"
          />
        </span>
        <div
          v-for="(filter, filterIndex) in filterGroup.filter_parts"
          :key="`filter-segment_${filterGroup.tableName}_${filter.label}`"
          class="flex flex-col"
        >
          <PopulationFilterSegment
            :read-only="!canEditPopulation"
            :is-edit="filter.isEdit || false"
            :is-first="filterIndex === 0"
            :combinator="getFilterExpression(filter, groupIndex)"
            :show-delete-empty-filter="filterGroup.filter_parts.length > 1"
            :our-pops="[population]"
            :filter="filter"
            :filter-sources="getOneSource(filterGroup.tableName)"
            :index="filterIndex"
            :feed="selectedFeed"
            :population-type="queryStandardType"
            :missing-sources="selectedFeedHasNoSources"
            @combinator-changed="onCombinatorChanged($event, groupIndex)"
            @filter-clickaway="
              createFilterGroupOrUpdateFilter($event, filterIndex)
            "
            @delete-filter="deleteFilter(filterIndex, groupIndex)"
          />
        </div>
        <div>
          <span
            :class="{
              'cursor-not-allowed': !canEditPopulation,
            }"
            class="population-filters__filter-expression-input-grouped"
            @click="addEmptyFilterToGroup('AND', groupIndex)"
          >
            And
          </span>
          <span
            :class="{
              'cursor-not-allowed': !canEditPopulation,
            }"
            class="population-filters__filter-expression-input-grouped"
            @click="addEmptyFilterToGroup('OR', groupIndex)"
          >
            Or
          </span>
        </div>
      </div>
      <BittsDivider
        v-if="isLastFilterGroup(groupIndex) || canUserAddFilterGroup"
        :all-caps="true"
        text="and"
        text-color="text-neutral-500"
        class="my-8"
      />
    </div>
    <div v-if="hasAvailableSources">
      <PopulationFilterSegment
        v-if="canUserAddFilterGroup"
        :read-only="!canEditPopulation"
        :is-edit="canUserAddFilterGroup"
        :our-pops="[population]"
        :filter-sources="sourceListWithRemovals"
        :filter="{}"
        :feed="selectedFeed"
        :show-delete-empty-filter="!!filterGroupList.length"
        :is-first="true"
        :index="0"
        :missing-sources="selectedFeedHasNoSources"
        :population-type="queryStandardType"
        class="p-8 mx-16 bg-neutral-100 rounded-bts-md"
        @filter-clickaway="createFilterGroupOrUpdateFilter"
        @delete-filter="handleCanAddFilterGroup(false)"
      />
      <BittsButton
        v-else-if="
          !canUserAddFilterGroup &&
          canEditPopulation &&
          sourceListWithRemovals.length
        "
        type="neutral"
        size="x-small"
        :left-icon="['fak', 'add']"
        :text="
          hasNewOnboarding
            ? 'Add filters from another object'
            : 'Add New Filter Group'
        "
        class="mt-20 ml-24"
        @click="handleCanAddFilterGroup(true)"
      />
    </div>
    <BittsAlert
      v-if="showEmptyFiltersWarning"
      message="Save this population with 0 filters?"
      :description="emptyFilterWarningMessage"
      color="warning"
      class="m-24"
    />
  </div>
</template>

<script setup>
import { BittsAlert, BittsButton, BittsDivider } from '@crossbeam/bitts';

import { capitalize } from 'humanize-plus';
import { computed, inject, ref } from 'vue';
import { useRoute } from 'vue-router';

import PopulationFilterSegment from '@/components/PopulationFilterSegment.vue';

import {
  FILE_UPLOAD_DATA_SOURCE_TYPE,
  GOOGLE_SHEETS_DATA_SOURCE_TYPE,
} from '@/constants/data_sources';
import { NEW_ONBOARDING } from '@/constants/feature_flags';
import { useFeatureFlagStore, useSourcesStore } from '@/stores';

const props = defineProps({
  population: {
    type: Object,
    default: () => ({}),
  },
  filterGroupList: {
    type: Array,
    default: () => [],
  },
  canEditPopulation: {
    type: Boolean,
    default: false,
  },
  canUserAddFilterGroup: {
    type: Boolean,
    default: false,
  },
  selectedFeed: {
    type: Object,
    default: () => ({}),
  },
  completeFilterSources: {
    type: Array,
    default: () => [],
  },
});

const emit = defineEmits([
  'add-filter-group',
  'delete-filter-group',
  'change-filter-group-list',
]);

const { hasFeatureFlag } = useFeatureFlagStore();
const hasNewOnboarding = computed(() => hasFeatureFlag(NEW_ONBOARDING));

const sourcesStore = useSourcesStore();

const route = useRoute();
const queryStandardType = computed(
  () => route.query && route.query.standardType,
);

const selectedFeedHasNoSources = computed(() =>
  props.selectedFeed
    ? sourcesStore.getSourcesByFeedId(props.selectedFeed.id).length > 0
    : false,
);

const isSheetsOrCSV = computed(() =>
  [FILE_UPLOAD_DATA_SOURCE_TYPE, GOOGLE_SHEETS_DATA_SOURCE_TYPE].includes(
    props.selectedFeed?.integration?.type,
  ),
);

const friendlyName = computed(
  () => props.selectedFeed?.integration?.friendly_name,
);

const showEmptyFiltersWarning = computed(() => {
  const feedName = friendlyName.value;
  if (!feedName) return false;
  if (feedName === 'CSV Upload' || feedName === 'Google Sheets') {
    return false;
  }
  return !props.filterGroupList.length;
});

const emptyFilterWarningMessage = computed(() => {
  const feedName = friendlyName.value;
  if (!feedName) return '';
  return `If there are no filters applied, your population will contain all synced ${feedName} records`;
});

const sourceListWithRemovals = computed(() => {
  const sourceNamesToRemove = props.filterGroupList.map(
    (group) => group.tableName,
  );
  return props.completeFilterSources.filter(
    (source) => !sourceNamesToRemove.includes(source.table),
  );
});

const hasAvailableSources = computed(
  () => !!sourceListWithRemovals.value.length,
);

const hoveredIndex = ref(-1);

const offlineSources = inject('offlineSources', []);
function createFilterGroupOrUpdateFilter(filterInfo, filterIndex) {
  const source = route.query?.offlinePartnerUuid
    ? offlineSources.value.find((s) => s.id === filterInfo.field.source_id)
    : sourcesStore.getSourceById(filterInfo.field.source_id);
  const filterGroupList = [...props.filterGroupList];
  let canUserAddFilterGroup = props.canUserAddFilterGroup;

  if (source) {
    const tableName = source.table;
    const mdmType = props.completeFilterSources.find(
      (source) => source.table === tableName,
    )?.mdm_type;
    const newFilterPart = {
      label: filterInfo.label,
      operand: filterInfo.operands,
      operator: filterInfo.operator.id,
      source_field_id: filterInfo.field.id,
    };
    const filterGroup = props.filterGroupList.find(
      (group) => group.tableName === tableName,
    );
    // if the filter group doesn't exist yet, add it
    if (!filterGroup) {
      const newFilterGroup = {
        filter_expression: [filterInfo.label],
        filter_parts: [newFilterPart],
        tableName,
        mdmType,
      };
      filterGroupList.push(newFilterGroup);
      canUserAddFilterGroup = false;
    } else {
      // if it does, the filter already exists (it could be empty, but it's there)
      // find it, and update it
      const groupIndex = props.filterGroupList.findIndex(
        (group) => group.tableName === tableName,
      );
      // add new filter part with new filter
      const newFilterParts = [...filterGroup.filter_parts];
      newFilterParts[filterIndex] = newFilterPart;
      // add new filter expression with new filter
      let newFilterExpression = [...filterGroup.filter_expression];
      // check to see if a filter with this label exists
      const existingFilterExpression = newFilterExpression.find(
        (expression) => expression === filterInfo.label,
      );
      if (!existingFilterExpression) {
        newFilterExpression = newFilterExpression
          .concat(filterInfo.combinator)
          .concat(filterInfo.label);
      }
      const newFilterGroup = {
        filter_expression: newFilterExpression,
        filter_parts: newFilterParts,
        tableName,
      };
      filterGroupList.splice(groupIndex, 1, newFilterGroup);
    }

    emit('add-filter-group', canUserAddFilterGroup);
    emit('change-filter-group-list', filterGroupList);
  }
}

function onCombinatorChanged(filter, groupIndex) {
  if (filter.combinator) {
    const expressionIndex = props.filterGroupList[
      groupIndex
    ].filter_expression.indexOf(filter.label);
    if (expressionIndex !== -1) {
      const newFilters = props.filterGroupList;
      newFilters[groupIndex].filter_expression[expressionIndex - 1] =
        filter.combinator;
      emit('change-filter-group-list', newFilters);
    }
  }
}

function addEmptyFilterToGroup(combinator, groupIndex) {
  const filterGroup = { ...props.filterGroupList[groupIndex] };
  filterGroup.filter_parts.push({
    isEdit: true,
    selectedCombinator: combinator,
  });
  const newFilters = props.filterGroupList;
  newFilters.splice(groupIndex, 1, filterGroup);
  emit('change-filter-group-list', newFilters);
}

function deleteFilter(filterIndex, groupIndex) {
  const newFilterGroupList = [...props.filterGroupList];
  const filterGroup = props.filterGroupList[groupIndex];
  const filterToRemove = filterGroup.filter_parts[filterIndex];
  // removing the filter from filter expression
  const filterExpressionIndex = filterGroup.filter_expression.indexOf(
    filterToRemove.label,
  );
  const newFilterExpression = [...filterGroup.filter_expression];
  if (filterExpressionIndex !== -1) {
    if (filterExpressionIndex !== 0) {
      // if it's not the first one, remove it and the combinator before it
      newFilterExpression.splice(filterExpressionIndex - 1, 2);
    } else {
      // if it's the first one, remove it and the combinator after it
      newFilterExpression.splice(filterExpressionIndex, 2);
    }
  }
  // removing the filter from filter parts
  if (filterGroup.filter_parts.length > 1) {
    let newFilterParts = [];
    if (filterToRemove.label) {
      newFilterParts = filterGroup.filter_parts.filter(
        (part) => part.label !== filterToRemove.label,
      );
    } else {
      // copying it so we don't mess with the original
      newFilterParts = filterGroup.filter_parts.slice();
      newFilterParts.splice(filterIndex, 1);
    }
    const newFilterGroup = {
      filter_parts: newFilterParts,
      filter_expression: newFilterExpression,
      tableName: filterGroup.tableName,
    };
    newFilterGroupList.splice(groupIndex, 1, newFilterGroup);
  } else {
    // if this is the last filter of the group, remove the entire filter group
    newFilterGroupList.splice(groupIndex, 1);
    // if this is the last filter group, enusre that "Add Filter" input renders
    emit('add-filter-group', true);
  }

  emit('change-filter-group-list', newFilterGroupList);
}

function handleCanAddFilterGroup(bool) {
  emit('add-filter-group', bool);
}

function handleDeleteFilterGroup(filterGroup) {
  if (!props.canEditPopulation) return;
  emit('delete-filter-group', filterGroup);
}

function toSingular(word) {
  if (word.endsWith('ies')) {
    return `${word.slice(0, -3)}y`;
  } else if (word.endsWith('s')) {
    return word.slice(0, -1);
  }
  return word;
}

function formatFilterTitle(filter) {
  if (!hasNewOnboarding.value || isSheetsOrCSV.value) {
    return capitalize(filter.tableName);
  }

  const tableName = capitalize(toSingular(filter.tableName));
  return ['account', 'lead'].includes(filter.mdmType)
    ? tableName
    : `for any ${tableName}`;
}

/* Filter Helpers 🤝 */
function getFilterExpression(filterPart, groupIndex) {
  const filterGroup = props.filterGroupList[groupIndex];
  const filterExpressionIndex = filterGroup.filter_expression.indexOf(
    filterPart.label,
  );
  if (filterExpressionIndex >= 0) {
    return filterGroup.filter_expression[filterExpressionIndex - 1];
  }
  return filterPart.selectedCombinator || '';
}

function getOneSource(tableName) {
  return props.completeFilterSources.filter(
    (source) => source.table === tableName,
  );
}

function isLastFilterGroup(index) {
  const lastIndex = props.filterGroupList.length - 1;
  return index !== lastIndex;
}
</script>

<style lang="pcss">
.population-filters {
}
.population-filters__group-name {
  @apply text-neutral-600 truncate max-w-[196px];
}

.population-filters__filter-expression-input-grouped {
  @apply text-neutral-500 font-bold text-sm cursor-pointer ml-4
  rounded-bts-sm py-4 px-8 border border-solid border-neutral-300;
}

.sidebar_filter-group-name {
  @apply text-neutral-600 truncate max-w-[196px];
}

.population-filters__delete-filter-group-text {
  @apply text-danger-text cursor-pointer;
}
</style>
