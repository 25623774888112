<template>
  <div class="c-sharing-settings-item">
    <div class="flex items-center gap-10">
      <BittsSvg
        :svg="
          dataShare.integrationType ? `${dataShare.integrationType}Icon` : ''
        "
        class="w-16 h-16 mr-2 min-w-[16px]"
      />
      <h3 class="font-bold">{{ dataShare.populationName }}</h3>
      <BittsSelect
        v-model="selectedSharing"
        class="c-sharing-settings-item__sharing"
        :class="{
          'c-sharing-settings-item__sharing--overlap-counts': isOverlapCounts,
        }"
        dropdown-class-name="c-sharing-settings-item__sharing--dropdown"
        list-height="300"
        :dropdown-match-select-width="false"
        :options="sharingOptions"
        :searchable="false"
        @change="(visibility) => handleChange({ visibility })"
        :disabled="!hasSharingPermission"
      >
        <template #option="{ option }">
          <div class="flex flex-col">
            <div
              class="c-sharing-settings-item__sharing-option-label"
              :class="{
                'text-warning-text': option.value === SHARING_TYPES.OLAPS_ONLY,
                'text-info-text': option.value !== SHARING_TYPES.OLAPS_ONLY,
              }"
            >
              {{ option.label }}
            </div>
            <div class="c-sharing-settings-item__sharing-option-description">
              {{ option.description }}
            </div>
          </div>
        </template>
      </BittsSelect>
    </div>
    <div class="flex items-center gap-16">
      <BittsSelect
        v-if="!isOverlapCounts"
        ref="selectRef"
        v-model="selectedPresetId"
        :has-opt-groups="true"
        class="c-sharing-settings-item__preset"
        dropdown-class-name="c-sharing-settings-item__preset-dropdown"
        :dropdown-match-select-width="false"
        :options="presetOptions"
        :searchable="false"
        @change="(presetId) => handleChange({ presetId })"
        :disabled="!hasSharingPermission"
      >
        <template #suffix="{ option }">
          <BittsButton
            v-if="option.value !== 'custom'"
            class="c-sharing-settings-item__preset-details-btn"
            variant="ghost"
            type="neutral"
            size="small"
            :center-icon="getPresetSuffixIcon(option.value)"
            @click="(e) => handleOpenPresetModal(e, option.value)"
          />
        </template>
      </BittsSelect>
      <SharingItemPartnerAvatars
        v-if="isFromDefaultSharing"
        :population-id="dataShare.populationId"
      />
      <BittsTooltip v-if="hasSharingPermission">
        <template #title>Unshare Population</template>
        <BittsButton
          class="c-sharing-settings-item__delete"
          @click="handleChange({ visibility: SHARING_TYPES.HIDDEN })"
          variant="outline"
          type="neutral"
          size="x-small"
          :center-icon="['far', 'xmark']"
        />
      </BittsTooltip>
      <BittsButton
        v-else
        class="c-sharing-settings-item__preset-details-btn"
        variant="ghost"
        type="neutral"
        size="small"
        :center-icon="getPresetSuffixIcon(Number(selectedPresetId))"
        @click="(e) => handleOpenPresetModal(e, Number(selectedPresetId))"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import {
  BittsButton,
  BittsSelect,
  BittsSvg,
  BittsTooltip,
} from '@crossbeam/bitts';
import { BittsIconPrefix } from '@crossbeam/bitts/src/types';

import { computed, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import useAuth from '@/composables/useAuth';
import { SHARING_TYPES, SharingType } from '@/constants/data_shares';
import { isGoogleOrCsvSource } from '@/constants/data_sources';
import { useDataSharesStore } from '@/stores';

import {
  DataShareItem,
  PresetOptionsGroup,
} from '../composables/useSharingSettings';

import SharingItemPartnerAvatars from './SharingItemPartnerAvatars.vue';

const { dataShare, presetOptions, updateDataShare } = defineProps<{
  dataShare: DataShareItem;
  presetOptions?: PresetOptionsGroup[];
  updateDataShare: (params: {
    populationId: number;
    visibility: SharingType;
    presetId?: number | null;
  }) => Promise<void>;
}>();

const router = useRouter();
const route = useRoute();
const { hasPermission } = useAuth();
const dataSharesStore = useDataSharesStore();

const selectRef = ref<InstanceType<typeof BittsSelect> | null>(null);
const isOpenFieldPresetsModal = ref(false);

const hasSharingPermission = computed(() => hasPermission('manage:sharing'));
const selectedSharing = computed(() => dataShare.visibility);
const selectedPresetId = computed(() => dataShare.presetId);
const isOverlapCounts = computed(
  () => selectedSharing.value === SHARING_TYPES.OLAPS_ONLY,
);
const isFromInvite = computed(() => route.name === 'home_accept_proposal');
const isFromInvitePartners = computed(() => route.name === 'accept_proposal');
const isFromDefaultSharing = computed(() => route.name === 'default-sharing');

async function handleChange(changes: {
  visibility?: SharingType;
  presetId?: number | 'custom';
}) {
  // View/edit existing preset
  if (isOpenFieldPresetsModal.value) return;

  // Create new preset
  if (changes.presetId === 'custom') {
    handleOpenCreatePresetModal();
    return;
  }

  // Select existing preset
  await updateDataShare({
    populationId: dataShare.populationId,
    visibility: changes.visibility ?? dataShare.visibility,
    presetId: changes.presetId ?? dataShare.presetId,
  });

  // Needed to ensure correct data across the app (like /'default-sharing' page)
  await dataSharesStore.refreshDataSharesStore();
}

// Triggered when preset is created/edited and its data being passed as query in the router.
watch(
  () => [route?.query.newPresetId, route?.query.presetId],
  ([newPresetId, presetId]) => {
    const id = newPresetId || presetId;
    if (id && Number(route.query.populationId) === dataShare.populationId) {
      handleChange({ presetId: Number(id) });
      const newQuery = { ...router.currentRoute.value.query };
      delete newQuery.newPresetId;
      delete newQuery.presetId;
      delete newQuery.populationId;
      delete newQuery.returnTo;
      router.replace({ query: newQuery });
    }
    isOpenFieldPresetsModal.value = false;
  },
);

// Helpers / statics

function handleOpenPresetModal(event: Event, presetId: number) {
  isOpenFieldPresetsModal.value = true;

  // Ensures dropdown removal without selecting related preset
  selectRef.value?.instantBlur();
  event.stopPropagation();
  event.preventDefault();

  const query = isFromInvite.value
    ? { ...route.query, populationId: dataShare.populationId }
    : { returnTo: 'settings', populationId: dataShare.populationId };

  const routeName = isFromInvite.value
    ? 'invite-sharing-preset-details'
    : isFromInvitePartners.value
      ? 'partners-invite-sharing-preset-details'
      : isFromDefaultSharing.value
        ? 'default-sharing-preset-details'
        : 'sharing-preset-details';

  router.push({
    name: routeName,
    params: {
      feed_id: dataShare.feedId,
      source_id: dataShare.sourceId,
      preset_id: presetId,
      ...(isFromDefaultSharing.value
        ? {}
        : {
            partner_org_id: dataShare.partnerId || route.params.partner_org_id,
          }),
    },
    query,
  });
}

function handleOpenCreatePresetModal() {
  const query = isFromInvite.value
    ? { ...route.query, populationId: dataShare.populationId }
    : { returnTo: 'settings', populationId: dataShare.populationId };

  const routeName = isFromInvite.value
    ? 'invite-sharing-create-preset'
    : isFromDefaultSharing.value
      ? 'default-sharing-create-preset'
      : 'sharing-create-preset';

  const sourceIdParam = isGoogleOrCsvSource(String(dataShare.integrationType))
    ? dataShare.sourceId
    : undefined;

  router.push({
    name: routeName,
    params: {
      feed_id: dataShare.feedId,
      source_id: sourceIdParam,
    },
    query,
  });
}

function getPresetSuffixIcon(presetId?: number) {
  if (!presetId) return null;

  const defaultPresetOption = presetOptions
    ?.flatMap((group) => group.items)
    .find((item) => item.label === 'Crossbeam default preset');

  const isCurrentOptionDefaultPreset = defaultPresetOption?.value === presetId;

  return [
    'far',
    hasSharingPermission.value && !isCurrentOptionDefaultPreset ? 'pen' : 'eye',
  ] as [BittsIconPrefix, string];
}

const sharingOptions = [
  {
    value: SHARING_TYPES.OLAPS_ONLY,
    label: 'Overlap counts',
    description: `You won't be sharing specific records, only statistics about your overlaps with this population.`,
  },
  {
    value: SHARING_TYPES.SHARING,
    label: 'Share overlaps',
    description: `Share population records only when they overlaps with the partner's data.`,
  },
  {
    value: SHARING_TYPES.GREENFIELD_SHARING,
    label: 'Share all',
    description:
      'Share all population records. This is recommended when you intend to bring net new leads to your partner (and they can do the same in return).',
  },
];
</script>

<style lang="pcss" scoped>
.c-sharing-settings-item {
  @apply flex justify-between p-16;
}
.c-sharing-settings-item__sharing-option-description {
  @apply text-neutral-text-weak text-sm text-wrap;
}
</style>

<style lang="pcss">
.c-sharing-settings-item__sharing {
  &.bitts-select
    .ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    @apply h-24 border-none rounded-[28px] shadow-none bg-info-background-weak;
  }
}
.c-sharing-settings-item__sharing--overlap-counts {
  &.bitts-select
    .ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    @apply bg-warning-background-weak;
  }
}
.c-sharing-settings-item__sharing--dropdown {
  @apply overflow-x-hidden;
  .ant-select-item {
    @apply w-[300px] p-10 flex items-center;
  }
}
.ant-select-selection-item {
  .c-sharing-settings-item__sharing-option-description,
  .c-sharing-settings-item__preset-details-btn {
    @apply hidden;
  }
}
.c-sharing-settings-item__preset-dropdown {
  @apply min-w-[400px] !important;

  & .rc-virtual-list-holder {
    @apply max-h-[240px] !important;
  }

  .ant-select-item:not(:last-child) .ant-select-item-option-content {
    @apply ml-24;
  }
  .ant-select-item-option-selected .ant-select-item-option-state {
    @apply absolute;
  }

  .c-bitts-btn--ghost {
    @apply bg-white;
  }
}

.ant-select-item-option-content {
  .c-sharing-settings-item__sharing-option-label {
    @apply font-bold text-neutral-text-strong !important;
  }
}
.c-sharing-settings-item__preset {
  &.bitts-select
    .ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    @apply h-24 border-none rounded-[28px] shadow-none;
  }

  &.bitts-select .ant-select-focused .ant-select-selector {
    @apply border-none shadow-none !important;
  }

  &.bitts-select .ant-select-selection-placeholder {
    @apply flex items-center;
  }
}
.c-sharing-settings-item__partner-btn {
  &.c-bitts-btn-small {
    @apply p-4;
  }
}
.c-sharing-settings-item__delete svg path {
  @apply text-danger-text-button;
}
</style>
