import { storeToRefs } from 'pinia';
import { computed, ref, watch } from 'vue';
import { useRoute } from 'vue-router';

import useAuth from '@/composables/useAuth';
import {
  ACTIVE_FEED_STATUS,
  COMPLETED_STATUSES,
  CONNECTION_CHECK_ERROR_STATUS,
  DELETING_FEED_STATUS,
  FILE_UPLOAD_DATA_SOURCE_TYPE,
  GOOGLE_SHEETS_DATA_SOURCE_TYPE,
  RECORD_LIMIT_EXCEEDED_STATUS,
  REQUIRED_FEED_STATUS,
  SOURCE_STATUS_MAP,
  WARNING_STATUSES,
} from '@/constants/data_sources';
import {
  COMPLETE,
  DELETING,
  ERRORED,
  LOCKED,
  NEEDS_PREVIOUS,
  NEEDS_SHEET,
  NOT_SYNCED,
  PENDING,
  PROGRESS,
  StatusType,
  StepType,
} from '@/constants/onboarding';
import { ls } from '@/local_storage';
import {
  useFeedsStore,
  useFileUploadsStore,
  usePartnersStore,
  usePopulationsStore,
  useSourcesStore,
  useTeamStore,
} from '@/stores';
import { Feed } from '@/types/feeds';

import useSeats from './useSeats';

type Step = {
  status: StatusType;
  isActive: boolean;
  subSteps?: string[];
};

const lsOnboardingCrmAdminSkip = ref(ls.onboardingCrmAdminSkip.get());
const activeStep = ref<null | number>(null);
export default function () {
  const route = useRoute();
  const feedsStore = useFeedsStore();
  const sourcesStore = useSourcesStore();
  const fileUploadsStore = useFileUploadsStore();
  const partnersStore = usePartnersStore();
  const teamStore = useTeamStore();

  const { feeds } = storeToRefs(feedsStore);
  const { uploadTables } = storeToRefs(fileUploadsStore);
  const { sources } = storeToRefs(sourcesStore);
  const { proposalsReceived, proposalsSent, partnerOrgs } =
    storeToRefs(partnersStore);
  const { hasScope, currentOrg } = useAuth();
  const { atSeatLimit } = useSeats();

  const isLoading = computed(
    () =>
      !partnersStore.ready ||
      !feedsStore.ready ||
      !sourcesStore.ready ||
      !fileUploadsStore.ready ||
      !teamStore.ready,
  );

  /* Invite partner */
  const canAddPartners = computed(() => hasScope('write:partnerships'));
  const invitePartnerStepStatus = computed(() => {
    if (proposalsSent.value.length || partnerOrgs.value.length) return COMPLETE;
    return canAddPartners.value ? PROGRESS : LOCKED;
  });

  // Select a specific proposal if id is specified, the last proposal received otherwise
  const request = computed(() => {
    const proposalId = route.query.proposal_id ?? null;
    if (proposalId) {
      const selectedRequest = proposalsReceived.value.find(
        (proposal) => proposal.id === Number(proposalId),
      );
      if (selectedRequest) {
        return selectedRequest;
      }
    }
    return proposalsReceived.value.slice(-1)[0];
  });

  const partner = computed(
    () => partnerOrgs.value[0] ?? request.value?.sending_organization ?? null,
  );

  const invitePartnerStep = computed<Step>(() => {
    return {
      isActive:
        activeStep.value === StepType.InvitePartner &&
        invitePartnerStepStatus.value !== COMPLETE,
      status: invitePartnerStepStatus.value,
    };
  });

  /* Import data */
  const canAddDataSources = computed(() => hasScope('write:data-sources'));

  type ErrorStatus = (typeof SOURCE_STATUS_MAP)['error'][number];
  const sourceErrorLookup = new Set<ErrorStatus>(SOURCE_STATUS_MAP.error);

  function isSheetsOrFileUpload(feed: Feed) {
    const sheetsOrUploadTypes = new Set([
      FILE_UPLOAD_DATA_SOURCE_TYPE,
      GOOGLE_SHEETS_DATA_SOURCE_TYPE,
    ]);
    return sheetsOrUploadTypes.has(feed.integration.type);
  }

  function getValidSources(feed: Feed) {
    const sources = sourcesStore.getSourcesByFeedId(feed.id);
    return sources.filter(
      (source) => !sourceErrorLookup.has(source.status as ErrorStatus),
    );
  }

  function hasValidSources(feed: Feed) {
    const sources = getValidSources(feed);
    return sources.length > 0;
  }

  const activeFeedsSortedByIntegration = computed(() => {
    if (feeds.value.length === 1) return feeds.value;
    return feeds.value
      .filter((feed) => {
        const isFeedValid = isFeedActiveOrWarning(feed);
        return isSheetsOrFileUpload(feed)
          ? isFeedValid && hasValidSources(feed)
          : isFeedValid;
      })
      .sort((a, b) => {
        const aIsCRM = isSheetsOrFileUpload(a);
        const bIsCRM = isSheetsOrFileUpload(b);

        if (aIsCRM && !bIsCRM) return -1;
        if (!aIsCRM && bIsCRM) return 1;
        return 0;
      });
  });

  const accumulatedSources = computed(() => {
    return activeFeedsSortedByIntegration.value.reduce((acc, feed) => {
      if (isSheetsOrFileUpload(feed)) {
        return acc + getValidSources(feed).length;
      }
      return acc + 1;
    }, 0);
  });

  const unconnectedDataSourceType = computed(() => {
    const inactiveFeed = feeds.value?.find((feed) => {
      if (isSheetsOrFileUpload(feed)) return !hasValidSources(feed);

      return feed.status !== ACTIVE_FEED_STATUS || !isFeedSyncComplete(feed);
    });
    return inactiveFeed?.integration.type || null;
  });

  const importDataStepStatus = computed<StatusType>(() => {
    const localFeeds = feeds.value;

    // if no feeds present
    if (localFeeds.length === 0) {
      return canAddDataSources.value ? PROGRESS : LOCKED;
    }

    const sourceFeedIds = sources.value.map((source) => source.feed_id);

    /**
     * Check if at least one feed qualifies as complete
     * If not, we can check the individual feed statuses
     */
    const completeFeedExists = localFeeds.some((feed) => {
      if (feed.integration.type === GOOGLE_SHEETS_DATA_SOURCE_TYPE) {
        const gSheetSources = sourcesStore.getSourcesByFeedId(feed.id);
        if (gSheetSources.length === 0) return false;
      }

      if (!isFeedActiveOrWarning(feed)) return false;

      return sourceFeedIds.includes(feed.id);
    });

    if (completeFeedExists) return COMPLETE;

    const currentFeed = localFeeds.at(0);
    if (!currentFeed && canAddDataSources.value) return PROGRESS;
    else if (!currentFeed) return LOCKED;

    /* Check whether they abandoned their CRM connection w/out choosing fields */
    if (
      !currentFeed.preview_sync_complete &&
      currentFeed.status === REQUIRED_FEED_STATUS
    ) {
      return NOT_SYNCED;
    }

    /* Check if their CRM connection is errored */
    if (
      [CONNECTION_CHECK_ERROR_STATUS, RECORD_LIMIT_EXCEEDED_STATUS].includes(
        currentFeed.status,
      )
    )
      return ERRORED;

    if (currentFeed.status === DELETING_FEED_STATUS) return DELETING;

    /* Check if they haven't added any sheets for their Google Sheets connection */
    if (currentFeed.integration.type === GOOGLE_SHEETS_DATA_SOURCE_TYPE) {
      const gSheetSources = sourcesStore.getSourcesByFeedId(currentFeed?.id);
      const connectionNeedsSheet = gSheetSources.length === 0;
      if (connectionNeedsSheet) return NEEDS_SHEET;
    }

    /* Check if the initial sync hasn't finished */
    if (!isFeedSyncComplete(currentFeed)) return PENDING;

    /* Check if any of their feeds are active, and have an associated source.
     * If so, then that source can be used for populations; the step is complete */
    const csvsSynced = localFeeds
      .filter(feedByType([FILE_UPLOAD_DATA_SOURCE_TYPE]))
      .some(isFeedActiveOrWarning);
    const csvsConnected = csvsSynced && uploadTables.value.length > 0;
    const activeGoogleSheetFeeds = localFeeds
      .filter(feedByType([GOOGLE_SHEETS_DATA_SOURCE_TYPE]))
      .filter(isFeedActiveOrWarning);

    const googleSheetsConnected = activeGoogleSheetFeeds.some((feed) =>
      sourceFeedIds.includes(feed.id),
    );
    const activeOtherFeeds = localFeeds
      .filter(
        feedByType(
          [FILE_UPLOAD_DATA_SOURCE_TYPE, GOOGLE_SHEETS_DATA_SOURCE_TYPE],
          false,
        ),
      )
      .filter(isFeedActiveOrWarning);
    const otherFeedsConnected = activeOtherFeeds.some((feed) =>
      sourceFeedIds.includes(feed.id),
    );
    if (googleSheetsConnected || csvsConnected || otherFeedsConnected)
      return COMPLETE;

    /* Otherwise, return default state */
    return canAddDataSources.value ? PROGRESS : LOCKED;
  });

  // Inviting CRM admin
  const canInviteTeammates = computed(() => hasScope('write:members'));
  const showInviteButton = computed(
    () => canInviteTeammates.value && !atSeatLimit.value,
  );
  const crmAdminEmails = computed(() =>
    teamStore.redeemableInvites.map((inv) => inv.email),
  );

  const inviteCrmAdminSkipped = computed(
    () => lsOnboardingCrmAdminSkip.value === currentOrg.value.id,
  );
  function skipInviteCrmAdmin() {
    ls.onboardingCrmAdminSkip.set(currentOrg.value.id);
    lsOnboardingCrmAdminSkip.value = currentOrg.value.id;
  }

  const inviteCrmAdminsStatus = computed<StatusType>(() => {
    if (crmAdminEmails.value.length === 0 && !canInviteTeammates.value)
      return LOCKED;
    if (crmAdminEmails.value.length > 0 || inviteCrmAdminSkipped.value)
      return COMPLETE;
    return PROGRESS;
  });

  const { populations } = storeToRefs(usePopulationsStore());
  const hasCustomersPopulation = computed(() =>
    populations.value.find((pop) => pop.standard_type === 'customers'),
  );

  const defineCustomersStatus = computed<StatusType>(() => {
    if (hasCustomersPopulation.value) return COMPLETE;
    return importDataStepStatus.value === COMPLETE ? PROGRESS : NEEDS_PREVIOUS;
  });

  const connectDataSubSteps = computed(() => [
    importDataStepStatus.value,
    inviteCrmAdminsStatus.value,
    defineCustomersStatus.value,
  ]);
  const connectDataStepStatus = computed(() => {
    if (connectDataSubSteps.value.some((status) => status === LOCKED))
      return LOCKED;
    if (defineCustomersStatus.value === COMPLETE) return COMPLETE;
    return PROGRESS;
  });

  const connectDataStep = computed<Step>(() => {
    const status = connectDataStepStatus.value;
    const isActive: boolean =
      activeStep.value === StepType.ConnectData ||
      (invitePartnerStepStatus.value === COMPLETE && status !== COMPLETE); // Auto-open if partner invited
    return {
      isActive,
      status,
      subSteps: connectDataSubSteps.value,
    };
  });

  const shareDataStep = computed<Step>(() => {
    const previousComplete =
      connectDataStepStatus.value === COMPLETE &&
      invitePartnerStepStatus.value === COMPLETE;
    return {
      // TODO: add logic to check if the user has shared data
      isActive: activeStep.value === StepType.ShareData,
      status: previousComplete ? PROGRESS : NEEDS_PREVIOUS,
    };
  });

  // Future logic related to Uncover Step here.
  const uncoverStep = computed<Step>(() => {
    return {
      isActive: activeStep.value === StepType.Uncover,
      status: NEEDS_PREVIOUS,
    };
  });

  /* Helper functions 🤝 */
  function getDefaultActiveStep() {
    if (invitePartnerStepStatus.value !== COMPLETE)
      return StepType.InvitePartner;
    if (defineCustomersStatus.value !== COMPLETE) return StepType.ConnectData;
    return StepType.ShareData;
  }

  function handleChangeActiveStep(type: StepType) {
    activeStep.value = type;
  }

  // Set active step if not already set
  watch(
    () => isLoading.value,
    (loading: boolean) => {
      if (!loading && activeStep.value === null) {
        activeStep.value = getDefaultActiveStep();
      }
    },
  );

  return {
    isLoading,
    invitePartnerStep,
    request,
    partner,
    crmAdminEmails,
    inviteCrmAdminsStatus,
    importDataStepStatus,
    connectDataStep,
    shareDataStep,
    uncoverStep,
    handleChangeActiveStep,
    getDefaultActiveStep,
    skipInviteCrmAdmin,
    inviteCrmAdminSkipped,
    defineCustomersStatus,
    unconnectedDataSourceType,
    showInviteButton,
    activeFeedsSortedByIntegration,
    accumulatedSources,
    hasCustomersPopulation,
  };
}

/* Helper functions 🤝 */
const feedByType =
  (types: string[], inclusive = true) =>
  (feed: Feed) => {
    return inclusive
      ? types.includes(feed.integration.type)
      : !types.includes(feed.integration.type);
  };

function isFeedSyncComplete(feed: Feed) {
  return feed.preview_sync_complete || feed.initial_sync_complete;
}

export function isFeedActiveOrWarning(feed: Feed) {
  return (
    [...COMPLETED_STATUSES, ...WARNING_STATUSES].includes(feed.status) &&
    isFeedSyncComplete(feed)
  );
}
