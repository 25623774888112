<template>
  <div class="flex justify-center items-center h-screen">
    <div class="flex flex-col items-center">
      <div class="mb-40">
        <BittsSvg svg="pam" />
      </div>
      <h1 class="text-xxxl font-bold text-neutral-text-strong mb-8">
        Coming soon!!!
      </h1>
      <h1 class="text-xxxl mb-8"> 🚀🚀🚀 </h1>
    </div>
  </div>
</template>

<script setup>
import { BittsSvg } from '@crossbeam/bitts';

import { useHead } from '@unhead/vue';

useHead({ title: 'Crossbeam - Coming Soon' });
</script>
