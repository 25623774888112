<template>
  <div class="c-integration-profile-settings-drawer">
    <BittsModalTwo
      v-if="confirmationModalType !== null"
      variant="confirm"
      :primary-button-text="isExitModal ? 'Exit' : 'Delete integration'"
      primary-button-type="danger"
      :title="isExitModal ? 'Exit without saving' : 'Delete integration'"
      :description="
        isExitModal
          ? 'You won’t be able to push your Crossbeam overlaps into your custom object until you finish the setup'
          : 'Are you sure you want to delete this integration?'
      "
      @action="isExitModal ? closeDrawerHandler() : deleteIntegration()"
      :action-loading="isDeleting"
      @closed="confirmationModalType = null"
    />
    <BittsDrawer
      visible
      v-if="enabledTray"
      destroy-on-close
      :title="enabledTray.name"
      :primary-button-text="saveButtonText"
      :is-saving="isSaving"
      :is-saving-disabled="
        isSaving ||
        !isIntegrationAvailable ||
        showEmptyState ||
        isDeleting ||
        disableSalesforceSave
      "
      @action:primary="saveChanges"
      @closed="onDrawerClosed"
    >
      <template #headerAvatar>
        <BittsAvatar
          :org="avatarLogo"
          :is-entity="true"
          :show-initials="true"
          size="medium"
          shape="square"
        />
      </template>

      <template #content>
        <div class="subtitle-section">
          <IntegrationCardHeader
            :statuses
            :loading="loading || isLoading"
            :callout-text
            :status-type="statusType as IntegrationProfileStatus"
            :disabled
            :version
            :error-type="firstError"
            @reauthorize="reauthorize"
          />
        </div>
        <template v-if="integrationProfileType">
          <div
            class="subtitle-section flex flex-col gap-4"
            :class="{ disabled }"
          >
            <div class="subtitle-section-content">
              <strong class="font-bold">Enable Data Push</strong>
              <BittsTooltip v-if="integrationProfileType === 'hubspot'">
                <FontAwesomeIcon
                  :icon="['fak', 'information-circle']"
                  class="text-neutral-accent ml-8"
                />
                <template #title>
                  Includes reports exported as lists into HubSpot
                </template>
              </BittsTooltip>
            </div>
            <div class="flex items-center">
              <p class="text-neutral-text text-sm">
                {{ enableDescription }}
              </p>
              <BittsSwitch
                v-model="isPushEnabled"
                :disabled
                class="ml-auto mr-2"
                data-testid="tray-enabled-toggle"
              />
            </div>
            <div
              v-if="hasOverlapsPushAccess"
              class="flex items-center mt-8 text-neutral-text-strong"
            >
              <BittsCheckbox
                label="Push to custom object"
                :checked="isOverlapsPushEnabled"
                :disabled="!isPushEnabled"
                @input="(val) => (isOverlapsPushEnabled = val)"
                data-testid="overlaps-push-checkbox"
              />
              <BittsTooltip>
                <FontAwesomeIcon
                  :icon="['fak', 'information-circle']"
                  class="text-neutral-accent"
                />
                <template #title>
                  Push overlap data to a Crossbeam custom object for detailed
                  partner insights.
                </template>
              </BittsTooltip>
            </div>
            <EcosystemFieldsSalesforce
              v-if="hasSalesforceEcosystemFieldAccess"
              :is-push-enabled
              :ecosystem-field-mappings
              :is-ecosystem-fields-push-enabled
              @changed:overlaps-push="handleEcosystemFieldsPushSettingChange"
              @changed:fields="(val) => (ecosystemFieldMappings = val)"
            />
            <div
              v-if="hasHubspotEcosystemFieldAccess"
              class="flex items-center mt-8 text-neutral-text-strong"
            >
              <BittsCheckbox
                label="Push to standard object"
                :checked="isEcosystemFieldsPushEnabled"
                :disabled="!isPushEnabled"
                @input="handleEcosystemFieldsPushSettingChange"
              />
              <BittsTooltip
                :include-cta="true"
                learn-more-link="https://help.crossbeam.com/en/articles/10445323-enhanced-ecosystem-reporting-in-hubspot-beta"
              >
                <FontAwesomeIcon
                  :icon="['fak', 'information-circle']"
                  class="text-neutral-accent"
                />
                <template #title>
                  Push "is a customer of" and "is an opportunity for" fields in
                  your standard Company object.
                </template>
              </BittsTooltip>
            </div>
            <div
              v-if="existingOpportunityPushSetting !== null"
              class="flex items-center gap-8 mt-8 text-neutral-text-strong"
            >
              <BittsCheckbox
                :checked="isOpportunityPushEnabled && isOverlapsPushEnabled"
                label="Push opportunity data"
                data-testid="opportunity-push-checkbox"
                :disabled="!isPushEnabled || !isOverlapsPushEnabled"
                @input="(val) => (isOpportunityPushEnabled = val)"
              />
            </div>
          </div>
          <div v-if="hasPermissionToManageIntegrations" class="p-16">
            <template v-if="!showEmptyState">
              <div class="flex items-center gap-8 mb-4">
                <FontAwesomeIcon
                  :icon="['fak', 'mapping']"
                  class="text-secondary-text"
                />
                <p class="font-bold"> Customize Data in Push </p>
              </div>
              <p class="text-neutral-text text-sm">
                {{ integrationDescription }}
                <BittsLink
                  :url="enabledTray.learnMore"
                  text="Learn more about this"
                />
              </p>
              <IntegrationSettings
                v-if="integrationProfileSettings"
                :population-settings="{
                  population_settings:
                    integrationProfileSettings?.population_settings ?? [],
                  standard_population_settings:
                    integrationProfileSettings?.standard_population_settings ??
                    [],
                  exclude_partnerships_created_since:
                    integrationProfileSettings?.exclude_partnerships_created_since,
                }"
                :setting-type="integrationProfileType"
                @save:changes="onSettingsUpdated"
                @update:exclude-partnerships-created-since="
                  onUpdateExcludePartnershipsCreatedSince
                "
                :is-disabled="
                  !isIntegrationAvailable || isSaving || loading || isLoading
                "
              />
            </template>
            <BittsEmptyState
              v-else
              title="The Salesforce App isn't installed"
              svg-name="emptyStateFreeAccount"
            >
              <template #subtitle>
                <div
                  class="sf-overlap-settings-drawer__empty-state--description"
                >
                  To use this integration, follow these steps below:
                </div>
                <ol class="list-decimal list-inside">
                  <li>
                    <BittsLink
                      url="https://appexchange.salesforce.com/appxListingDetail?listingId=a0N3A00000FvKuqUAF"
                      text="Install Package"
                    />
                  </li>
                  <li>
                    <BittsLink
                      :url="sfCrossbeamAppLink"
                      text="Setup Salesforce Crossbeam App"
                      :open-in-new-tab="true"
                    />
                  </li>
                </ol>
              </template>
            </BittsEmptyState>
          </div>
        </template>
      </template>
      <template #secondaryButton>
        <BittsButton
          text="Delete integration"
          :left-icon="['fak', 'delete']"
          variant="ghost"
          class="w-1/2"
          type="danger"
          data-testid="delete-integration-button"
          @click="confirmationModalType = 'delete'"
          :disabled="isSaving || !hasPermissionToManageIntegrations"
        />
      </template>
    </BittsDrawer>
  </div>
</template>
<script setup lang="ts">
import {
  BittsAvatar,
  BittsButton,
  BittsCheckbox,
  BittsDrawer,
  BittsEmptyState,
  BittsLink,
  BittsModalTwo,
  BittsSwitch,
  BittsTooltip,
} from '@crossbeam/bitts';

import { ComputedRef, computed, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import IntegrationCardHeader from '@/components/integrations/IntegrationCardHeader.vue';

import { crossbeamApi } from '@/api';
import useAuth from '@/composables/useAuth';
import useIntegrationProfile from '@/composables/useIntegrationProfile';
import useTrayIntegrations from '@/composables/useTrayIntegrations';
import useTrayStatus from '@/composables/useTrayStatus';
import appConfig from '@/config';
import { MAP_TRAY_TO_INTEGRATION_PROFILE_TYPE } from '@/constants/integrations';
import { useFlashesStore, usePartnerCloudStore } from '@/stores';
import { IntegrationProfileStatus } from '@/types/integration_profiles';
import { TrayTag } from '@/types/integrations';
import type { Organization } from '@/types/root';

import EcosystemFieldsSalesforce from './EcosystemFieldsSalesforce.vue';
import IntegrationSettings from './IntegrationSettings.vue';

const route = useRoute();
const router = useRouter();
const pathTrayTag = computed(() => route.params.tray_tag as TrayTag);

const { currentOrg } = useAuth() as {
  currentOrg: ComputedRef<Organization>;
  hasPermission: (permission: string) => boolean;
};
const { addErrorFlash } = useFlashesStore();
const partnerCloudStore = usePartnerCloudStore();

const integrationProfileType = computed(() => {
  if (
    pathTrayTag.value &&
    pathTrayTag.value in MAP_TRAY_TO_INTEGRATION_PROFILE_TYPE
  ) {
    const value = MAP_TRAY_TO_INTEGRATION_PROFILE_TYPE[pathTrayTag.value];
    if (!value) {
      throw Error('Integration profile type not found');
    }
    return value;
  }

  return undefined;
});

const {
  clearProfilesState,
  enableDescription,
  hasPermissionToManageIntegrations,
  integrationDescription,
  integrationProfileSettings,
  isIntegrationAvailable,
  isLoading,
  isSaving,
  onSettingsUpdated,
  onUpdateExcludePartnershipsCreatedSince,
  saveSettings,
  enabledTray,
  existingOverlapsPushSetting,
  existingOpportunityPushSetting,
  existingEcosystemFieldsPushSetting,
  isTrayConfigUpdateRequired,
  isPushEnabled,
  ecosystemFieldMappings,
  isOpportunityPushEnabled,
  isOverlapsPushEnabled,
  isEcosystemFieldsPushEnabled,
  handleEcosystemFieldsPushSettingChange,
  hasTempPushExtraFields,
  hasTempSFDCFields,
} = useIntegrationProfile(integrationProfileType.value);

const {
  statusType,
  calloutText,
  statuses,
  hasSalesforceConnection,
  version,
  firstError,
} = useTrayStatus(enabledTray);

const loading = ref(false);
const isDeleting = ref(false);
const confirmationModalType = ref<'exit' | 'delete' | null>(null);

const disabled = computed(() => {
  const disableIfSalesforce =
    integrationProfileType.value === 'salesforce' &&
    !hasSalesforceConnection?.value;
  return (
    !hasPermissionToManageIntegrations.value ||
    !isIntegrationAvailable.value ||
    isSaving.value ||
    disableIfSalesforce
  );
});

const isExitModal = computed(() => confirmationModalType.value === 'exit');

const avatarLogo = computed(() => {
  return enabledTray?.value?.avatar
    ? {
        logo_url: new URL(
          `../../assets/pngs/partner-cloud/${enabledTray?.value?.avatar}`,
          import.meta.url,
        ).href,
      }
    : null;
});

const disableSalesforceSave = computed(() => {
  return (
    hasSalesforceEcosystemFieldAccess.value &&
    isEcosystemFieldsPushEnabled.value &&
    !ecosystemFieldMappings.value?.every((field) => !!field.crm_field)
  );
});

const hasOverlapsPushAccess = computed(
  () =>
    (integrationProfileType.value === 'hubspot' ||
      integrationProfileType.value === 'salesforce') &&
    existingOverlapsPushSetting.value !== null,
);

const hasHubspotEcosystemFieldAccess = computed(
  () =>
    integrationProfileType.value === 'hubspot' &&
    hasTempPushExtraFields &&
    existingEcosystemFieldsPushSetting.value !== null,
);

const hasSalesforceEcosystemFieldAccess = computed(
  () =>
    integrationProfileType.value === 'salesforce' &&
    hasTempSFDCFields &&
    existingEcosystemFieldsPushSetting.value !== null,
);

const saveButtonText = computed(() =>
  isSaving.value ? 'Saving Changes' : 'Save Changes',
);

const showEmptyState = computed(() => {
  return (
    integrationProfileType.value === 'salesforce' &&
    !hasSalesforceConnection?.value
  );
});

const sfCrossbeamAppLink = computed(() => {
  return `https://login.salesforce.com/${appConfig.salesforceReauthUrl}`;
});

const onDrawerClosed = () => {
  if (
    !enabledTray?.value?.enabled &&
    partnerCloudStore.inProgressInstallation
  ) {
    confirmationModalType.value = 'exit';
  } else {
    closeDrawerHandler();
  }
};

const closeDrawerHandler = () => {
  clearProfilesState();
  confirmationModalType.value = null;
  router.push({ name: 'integrations', query: { scroll: 'true' } });
};

const saveChanges = async () => {
  if (!integrationProfileType.value) {
    onDrawerClosed();
  } else {
    await saveSettings();
    onDrawerClosed();
  }
};

const reauthorize = async () => {
  if (!enabledTray?.value) return;

  const { openConfigWindow } = useTrayIntegrations();
  try {
    loading.value = true;
    const body = {
      org_name: currentOrg.value.name,
      instance_name: enabledTray.value?.name,
      solution_id: enabledTray.value?.solution.id,
      instance_id: enabledTray.value?.id,
    };
    const { data } = await crossbeamApi.PATCH('/v0.1/tray-integrations', {
      body,
    });
    openConfigWindow(data);
  } catch (err) {
    addErrorFlash({
      message: 'Something went wrong',
      description: `If this error persists, please contact
      <a target="_blank" href="mailto:support@crossbeam.com">support@crossbeam.com</a>.`,
    });
  } finally {
    loading.value = false;
  }
};

async function deleteIntegration() {
  if (!enabledTray.value?.id) return;
  try {
    isDeleting.value = true;
    await partnerCloudStore.deleteIntegration(enabledTray.value);
  } catch (error) {
    addErrorFlash({
      message: 'Failed to delete integration',
    });
  } finally {
    isDeleting.value = false;
    closeDrawerHandler();
  }
}

defineExpose({
  isTrayConfigUpdateRequired,
});
</script>

<style scoped lang="pcss">
.subtitle-section {
  @apply p-16 border-b border-neutral-background-disabled;
}
.subtitle-section-content {
  @apply flex items-center;
}

.c-integration-settings-alert :deep(div.self-start.pt-4) {
  @apply self-center pt-0 !important;
}
</style>
