<template>
  <BittsModalTwo
    class="c-sharing-item-partners-modal"
    :width="600"
    @closed="close"
    secondary-button-text="Close"
    :show-primary-button="false"
    :use-mask-to-close="true"
  >
    <template #header>
      <div class="flex flex-col gap-20 px-16 py-20 w-full">
        <div class="text-lg font-bold">
          Customers - Partners Sharing Settings
        </div>
        <BittsSegment
          :tabs="tabs"
          :active-index="activeTabIndex"
          @change:segment="handleChangeSegment"
        />
      </div>
    </template>
    <div
      class="flex flex-col p-16 max-h-[367px]"
      :style="{ height: `${heightPartnerWrapper}px` }"
    >
      <div
        v-for="partner in partners"
        :key="partner.id"
        class="flex items-center gap-4 py-8"
      >
        <BittsAvatar :org="partner" size="x-small" />
        {{ partner.name }}
      </div>
    </div>
  </BittsModalTwo>
</template>

<script setup lang="ts">
import { BittsAvatar, BittsSegment } from '@crossbeam/bitts';
import BittsModalTwo from '@crossbeam/bitts/src/BittsModalTwo/BittsModalTwo.vue';

import { storeToRefs } from 'pinia';
import { computed, ref } from 'vue';

import { usePartnersStore } from '@/stores';
import { Partner } from '@/types/partners';

type Props = {
  partnersUsingDefaults: Partner[];
  close: () => void;
};
const { partnersUsingDefaults } = defineProps<Props>();

const { partnerOrgs } = storeToRefs(usePartnersStore());

const activeTab = ref('default-data-sharing');

const activeTabIndex = computed(() =>
  tabs.value.findIndex((tab) => tab.id === activeTab.value),
);

const partnersNotUsingDefaults = computed(() =>
  partnerOrgs.value.filter(
    (partner) =>
      !partnersUsingDefaults?.some(
        (partnerUsingDefault) => partnerUsingDefault.id === partner.id,
      ),
  ),
);

const partners = computed(() =>
  activeTab.value === tabType.DEFAULT_DATA_SHARING
    ? partnersUsingDefaults
    : partnersNotUsingDefaults.value,
);

const heightPartnerWrapper = computed(() => {
  const maxPartners = Math.max(
    partnersUsingDefaults.length,
    partnersNotUsingDefaults.value.length,
  );
  return maxPartners * 37 + 32; // 37 = height of a partner item, 32 = p-y of partner wrapper
});

const tabs = computed(() => [
  {
    title: 'Default Data Sharing',
    id: tabType.DEFAULT_DATA_SHARING,
    count: partnersUsingDefaults.length,
  },
  {
    title: 'Others',
    id: tabType.OTHERS,
    count: partnersNotUsingDefaults.value.length,
    tooltipProps: {
      title:
        'Default sharing is off for the following partners. You might share the population with custom settings, or hide it from these partners.',
    },
  },
]);

function handleChangeSegment(tabId: string) {
  activeTab.value = tabId;
}

// Helpers

const tabType = {
  DEFAULT_DATA_SHARING: 'default-data-sharing',
  OTHERS: 'others',
};
</script>

<style lang="pcss">
.c-sharing-item-partners-modal {
  &.o-bitts-modal.bitts-modal--primary dialog .o-bitts-modal__header,
  &.o-bitts-modal.bitts-modal--primary .o-bitts-modal__content {
    @apply m-0 p-0;
  }

  &.o-bitts-modal dialog {
    @apply pb-0;
  }

  .bitts-segment {
    @apply w-full;
    & > div {
      @apply w-[50%];
    }
    & .bitts-segment__tab {
      @apply w-full flex justify-center;
    }
  }
}
</style>
