import useMinimalApp from '@/composables/useMinimalApp';
import appConfig from '@/config';
import { ADMIN_PANEL } from '@/constants/team';

export const enableIntercom = (currentUser, currentOrg) => {
  const settings = {
    app_id: appConfig.intercom.workspaceId,
    email: currentUser.email,
    company: {
      company_id: currentOrg.id,
      name: currentOrg.name,
    },
  };
  if (currentUser.first_name) {
    settings.name = `${currentUser.first_name} ${currentUser.last_name}`;
  }
  window.intercomSettings = settings;

  if (currentUser.email) {
    (function () {
      const w = window;
      const ic = w.Intercom;
      if (typeof ic === 'function') {
        ic('reattach_activator');
        ic('update', w.intercomSettings);
      } else {
        const d = document;
        const i = function () {
          i.c(arguments);
        };
        i.q = [];
        i.c = function (args) {
          i.q.push(args);
        };
        w.Intercom = i;
        const l = function () {
          const s = d.createElement('script');
          s.type = 'text/javascript';
          s.async = true;
          s.src = `https://widget.intercom.io/widget/${appConfig.intercom.workspaceId}`;
          const x = d.getElementsByTagName('script')[0];
          x.parentNode.insertBefore(s, x);
        };
        l();
      }
    })();
  }
};

export const maybeEnableIntercom = (currentUser, currentOrg, currentAuth) => {
  const { isMinimalView } = useMinimalApp();
  if (!appConfig.intercom.enabled || isMinimalView) {
    return;
  }
  if (currentAuth && currentAuth.authorizer_type === ADMIN_PANEL) {
    return;
  }
  enableIntercom(currentUser, currentOrg);
};
